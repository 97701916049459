import {
  ServiceType,
  WeekDay,
  WeekDayDto,
} from '@wix/bookings-uou-types/dist/src';

export interface daysOfferedTranslations {
  allWeek: string;
}

export class DaysOfferedMapper {
  constructor(
    private formatter,
    private locale: string,
    private options: daysOfferedTranslations,
    private isMondayFirstDayOfWeek,
  ) {}

  public text({
    serviceType,
    daysDetails,
  }: {
    serviceType: ServiceType;
    daysDetails: WeekDayDto[];
  }) {
    if (serviceType === ServiceType.GROUP) {
      const shouldDisplayDay = (expirationDate) =>
        !expirationDate ||
        new Date(expirationDate).getTime() > new Date().getTime();

      const days = daysDetails
        .filter((dayDetails) => shouldDisplayDay(dayDetails.expirationDate))
        .map((dayDetails) => dayDetails.day);

      if (days.length === 7) {
        return this.formatter(this.options.allWeek);
      }

      return this.getDaysText(days);
    }
    return '';
  }

  private getDaysText(days: WeekDay[]) {
    const weekDays = [
      WeekDay.SUNDAY,
      WeekDay.MONDAY,
      WeekDay.TUESDAY,
      WeekDay.WEDNESDAY,
      WeekDay.THURSDAY,
      WeekDay.FRIDAY,
      WeekDay.SATURDAY,
    ];

    const aSunday = '1/1/2017';
    const weekdayFormat = days.length <= 2 ? 'long' : 'short';
    const dateTimeFormat = new Intl.DateTimeFormat(this.locale, {
      weekday: weekdayFormat,
    });
    const daysIndex = days
      .map((day) => weekDays.findIndex((weekDay) => weekDay === day))
      .sort();

    if (this.isMondayFirstDayOfWeek && daysIndex[0] === 0) {
      const sunday = daysIndex.shift();
      daysIndex.push(sunday as number);
    }

    return daysIndex
      .map((dayIndex) => {
        const newDate = new Date(aSunday);
        newDate.setDate(newDate.getDate() + dayIndex);
        return dateTimeFormat.format(newDate);
      })
      .join(', ');
  }
}
