import { WidgetConfig } from '../types/shared-types';
import { DaysOfferedMapper } from '../mappers/days-offered.mapper';
import {
  DurationMapper,
  durationMapperOptions,
} from '../mappers/duration.mapper';
import { PaymentDtoMapper } from '../mappers/payment-dto.mapper';
import { LocationMapper } from '../mappers/location.mapper';

export interface TitleAndTaglineViewModel {
  title?: string;
  tagline?: string;
}
export interface DetailsSectionViewModel {
  daysOffered?: string;
  duration?: string;
  price?: string;
  location?: string;
}
export interface ServicePageViewModel {
  titleAndTagline: TitleAndTaglineViewModel;
  detailsSection: DetailsSectionViewModel;
}

export const servicePageViewModelFactory = ({
  config,
  translations,
}: {
  config: WidgetConfig;
  translations;
}): ServicePageViewModel => {
  if (!config?.serviceDto) {
    return {
      titleAndTagline: {
        title: '',
        tagline: '',
      },
      detailsSection: {
        daysOffered: '',
        duration: '',
        price: '',
        location: '',
      },
    };
  }
  console.log(config);
  const serviceDto = config?.serviceDto;
  const businessInfo = config?.businessInfo;
  const serviceInfo = serviceDto?.info;

  const locale = businessInfo?.regionalSettingsLocale ?? 'en-US';
  const formatter = (key) => translations[key] ?? key;
  const serviceType = serviceDto?.type;
  const isDurationVaries = serviceDto?.scheduleHeader?.isDurationVaries;
  const startDateAsUTC = serviceDto?.scheduleHeader?.startDateAsUTC;
  const durationInMinutes = serviceDto?.scheduleHeader?.durationInMinutes;
  const daysOffered = new DaysOfferedMapper(
    formatter,
    locale,
    { allWeek: 'days-offered.all-week' },
    false,
  ).text({
    serviceType,
    daysDetails: serviceDto?.scheduleHeader?.daysDetails ?? [],
  });
  const durationOptions: durationMapperOptions = {
    hourUnit: 'duration.units.hours',
    minuteUnit: 'duration.units.minutes',
    durationVaries: 'duration.varies',
    coursePassedText: 'service.course.schedule.start-date-passed',
    courseInFutureText: 'service.course.schedule.start-date-in-future',
  };
  const duration = new DurationMapper(
    locale,
    durationOptions,
    formatter,
  ).durationText({
    serviceType,
    isDurationVaries,
    startDateAsUTC,
    durationInMinutes,
  });

  const price = new PaymentDtoMapper(locale).priceText(serviceDto?.payment);
  const location = new LocationMapper().text({
    serviceLocation: serviceInfo?.location,
    clientLocationText: 'Customer place',
  });
  return {
    titleAndTagline: {
      title: serviceInfo?.name,
      tagline: serviceInfo?.tagLine,
    },
    detailsSection: {
      daysOffered,
      duration,
      price,
      location,
    },
  };
};
