import {
  CreateControllerFn,
  ControllerParams,
} from 'yoshi-flow-editor-runtime';
import { getSettingsValues } from '@wix/tpa-settings';
import { componentSettings } from './componentSettings';
import HttpClient from 'yoshi-serverless-client';
import { getConfig } from '../../api/config.api';
import { servicePageViewModelFactory } from '../../service-page-view-model/servicePageViewModel';

const createController: CreateControllerFn = async ({
  flowAPI,
  appData,
}: ControllerParams) => {
  const errorReporter = appData?.ravenReporter || (() => null);
  const { setProps } = flowAPI.controllerConfig;

  const publicData = flowAPI.controllerConfig.config.publicData.COMPONENT || {};
  const settings = getSettingsValues(publicData, componentSettings);

  return {
    async pageReady() {
      const instance = flowAPI.controllerConfig.appParams.instance as string;
      const wixCodeApi = flowAPI.controllerConfig.wixCodeApi;
      const serviceSlugIndex = wixCodeApi.location.path.length - 1;
      const serviceSlug = wixCodeApi.location.path[serviceSlugIndex];
      const translations = await flowAPI.getTranslations();
      const httpClient = new HttpClient();
      const config = await httpClient.request(getConfig, {
        headers: { authorization: instance },
      })(serviceSlug);
      const viewModel = servicePageViewModelFactory({
        config,
        translations,
      });
      setProps({
        config,
        viewModel,
        settings,
      });
    },
    updateConfig($w, config) {
      const updatedPublicData = config.publicData.COMPONENT || {};
      const updatedSettings = getSettingsValues(
        updatedPublicData,
        componentSettings,
      );

      setProps({
        settings: updatedSettings,
      });
    },
  };
};

export default createController;
