import { ISettingsParam, ISettingsParams } from '@wix/tpa-settings';
// import { appName } from '../../../.application.json';
import { AlignmentOptions, ISection, SectionTypes } from './types';

export type IComponentSettings = ISettingsParams<{
  policySectionTitle: ISettingsParam<string>;
  contentWidth: ISettingsParam<number>;
  sections: ISettingsParam<ISection[]>;
  headerVisibility: ISettingsParam<boolean>;
  sidebarVisibility: ISettingsParam<boolean>;
  serviceTitleVisibility: ISettingsParam<boolean>;
  serviceTaglineVisibility: ISettingsParam<boolean>;
  titleAndTaglineVisibility: ISettingsParam<boolean>;
  detailsDaysVisibility: ISettingsParam<boolean>;
  detailsDurationVisibility: ISettingsParam<boolean>;
  detailsPriceVisibility: ISettingsParam<boolean>;
  detailsLocationVisibility: ISettingsParam<boolean>;
  detailsBoxAlignment: ISettingsParam<AlignmentOptions>;
  detailsTextAlignment: ISettingsParam<AlignmentOptions>;
  detailsButtonAlignment: ISettingsParam<AlignmentOptions>;
  titleAndTaglineAlignment: ISettingsParam<AlignmentOptions>;
  businessEmailVisibility: ISettingsParam<boolean>;
  businessPhoneNumberVisibility: ISettingsParam<boolean>;
  businessAddressVisibility: ISettingsParam<boolean>;
  contactDetailsTitleText: ISettingsParam<string>;
  descriptionTitleText: ISettingsParam<string>;
}>;

export const defaultSections: ISection[] = [
  {
    type: SectionTypes.TITLE_TAGLINE,
    visible: true,
    bookButton: false,
  },
  {
    type: SectionTypes.DESCRIPTION,
    visible: true,
    bookButton: false,
  },
  {
    type: SectionTypes.DETAILS,
    visible: true,
    bookButton: false,
  },
  {
    type: SectionTypes.POLICY,
    visible: true,
    bookButton: false,
  },
  {
    type: SectionTypes.CONTACT,
    visible: true,
    bookButton: false,
  },
  {
    type: SectionTypes.SCHEDULING,
    visible: true,
    bookButton: false,
  },
];

export const componentSettings: IComponentSettings = {
  contentWidth: {
    key: 'contentWidth',
    getDefaultValue: () => 50,
  },
  sections: {
    key: 'sections',
    getDefaultValue: () => defaultSections,
  },
  headerVisibility: {
    key: 'headerVisibility',
    getDefaultValue: () => true,
  },
  sidebarVisibility: {
    key: 'sidebarVisibility',
    getDefaultValue: () => true,
  },
  serviceTitleVisibility: {
    key: 'serviceTitleVisibility',
    getDefaultValue: () => true,
  },
  titleAndTaglineVisibility: {
    key: 'titleAndTaglineVisibility',
    getDefaultValue: () => true,
  },
  serviceTaglineVisibility: {
    key: 'serviceTaglineVisibility',
    getDefaultValue: () => true,
  },
  titleAndTaglineAlignment: {
    key: 'titleAndTaglineAlignment',
    getDefaultValue: (): AlignmentOptions => AlignmentOptions.CENTER,
  },
  policySectionTitle: {
    key: 'policySectionTitle',
    getDefaultValue: () => '',
  },
  detailsDaysVisibility: {
    key: 'detailsDaysVisibility',
    getDefaultValue: () => true,
  },
  detailsDurationVisibility: {
    key: 'detailsDurationVisibility',
    getDefaultValue: () => true,
  },
  detailsPriceVisibility: {
    key: 'detailsPriceVisibility',
    getDefaultValue: () => true,
  },
  detailsLocationVisibility: {
    key: 'detailsLocationVisibility',
    getDefaultValue: () => true,
  },
  detailsBoxAlignment: {
    key: 'detailsBoxAlignment',
    getDefaultValue: (): AlignmentOptions => AlignmentOptions.STRETCH,
  },
  detailsTextAlignment: {
    key: 'detailsTextAlignment',
    getDefaultValue: (): AlignmentOptions => AlignmentOptions.CENTER,
  },
  detailsButtonAlignment: {
    key: 'detailsButtonAlignment',
    getDefaultValue: (): AlignmentOptions => AlignmentOptions.LEFT,
  },
  businessEmailVisibility: {
    key: 'businessEmailVisibility',
    getDefaultValue: () => true,
  },
  businessPhoneNumberVisibility: {
    key: 'businessPhoneNumberVisibility',
    getDefaultValue: () => true,
  },
  businessAddressVisibility: {
    key: 'businessAddressVisibility',
    getDefaultValue: () => true,
  },
  contactDetailsTitleText: {
    key: 'contactDetailsTitleText',
    getDefaultValue: () =>
      'app.settings.sections.contact.default.contact-details-title.label',
  },
  descriptionTitleText: {
    key: 'descriptionTitleText',
    getDefaultValue: () => '',
  },
};
